import axios from 'axios';
// import Lodash from 'lodash';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// eslint-disable-next-line import/prefer-default-export
export const newDeposito = params => {
  const { depositoData } = params;

  const infoDeposito = {
    deposito: depositoData,
  };

  return axios.post(
    '/depositos/saveDepositoED',
    infoDeposito,
  );
};

export const getDataComprobante = params => {
  return axios.post('/comprobante/getInfoEc', params);
};

import React, { useState } from 'react';
import publicIp from 'public-ip';
// import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Lodash from 'lodash';
// import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import style from './styles';

// import useAccount from '../../Redux/Common/Account/accountHook';
import { login } from '../../Core/Api/accountApi';
import Storage from '../../Storage/Storage';
import ConfigPage from '../../Storage/ConfigPage';

// import { getProfile } from '../../Core/Api';

const Login = props => {
  const { classes } = props;

  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = { nickname: '', password: '' };

  const validationSchema = Yup.object({
    nickname: Yup.string().required('Campo Requerido'),
    password: Yup.string().required('Campo Requerido'),
  });

  const storageInstance = Storage.instance;
  const storagePageInstance = ConfigPage.instance;

  const onSubmit = values => () => {
    const { nickname, password } = values;
    setSubmitError(false);
    setSubmitErrorMsg('');
    setIsSubmitting(true);

    if (nickname.length > 0 && password.length > 0) {
      publicIp.v4().then(ip => {
        login({
          nickname,
          password,
          ipAddress: ip,
        }).then(res => {
          const isToken = Lodash.get(
            res,
            ['data', 'response', 'token'],
            null,
          );

          if (isToken !== null) {
            setSubmitError(false);
            setSubmitErrorMsg('');
            const responseToken = res.data.response.token;

            storageInstance.setToken(responseToken);
            storagePageInstance.setStorage({
              showHeader: false,
              showFooter: true,
            });
            window.location.href = '/dashboard';
          } else {
            setSubmitError(true);
            setSubmitErrorMsg(
              'Usuario o Contraseña son inválidos',
            );
          }
          setIsSubmitting(false);
        });

        // eslint-disable-next-line no-unused-vars
        const resToken =
          // eslint-disable-next-line max-len
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb2RfdXN1YXJpbyI6MTI0NCwiaW5kX2VzdGFkbyI6MSwiZGVzX2xvZ2luIjoiNDAxOTIwMjM4IiwiZGVzX25vbWJyZSI6IkVTVEVCQU4gUklDQVJETyBSQU1JUkVaIFJVSVoiLCJudW1fY2VkdWxhIjo0MDE5MjAyMzgsInRpcG9fdXN1YXJpbyI6IkdFUyIsImNvZF9wZXJpb2RvIjoxNywic3lzdGVtX2VuYWJsZWQiOnRydWUsImlhdCI6MTYxMTQ3MTQzMywibG9naW5fZGF0ZSI6IjIwMjEtMDEtMjQifQ.OxWlMDhuhXB-18-HpJjero7EAcKzPypbgr1oONImJxs';
      });
    } else {
      setSubmitError(true);
      setSubmitErrorMsg(
        'Favor ingrese su usuario y contraseña',
      );
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  Login.propTypes = {};

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.loginBox}
      >
        <h2 className={classes.title}>
          Ingreso de Gestores
        </h2>
        <h4 className={classes.subtitle}>
          PLATAFORMA DE ELECCIONES CANTONALES
        </h4>
        <form className={classes.form}>
          {submitError && (
            <div className={classes.formError}>
              <Alert severity="error">
                {submitErrorMsg}
              </Alert>
            </div>
          )}

          <label
            htmlFor="nickname"
            className={classes.labelText}
          >
            Usuario
          </label>
          <input
            className={clsx(
              classes.input,
              formik.errors.email && classes.inputError,
            )}
            id="nickname"
            name="nickname"
            label="nickname"
            placeholder=""
            onChange={formik.handleChange}
            value={formik.values.nickname}
          />
          {formik.errors.nickname && (
            <div
              className={classes.error}
              data-type="error"
            >
              {formik.errors.nickname}
            </div>
          )}
          <label
            htmlFor="password"
            className={classes.labelText}
          >
            Contraseña
          </label>
          <input
            className={clsx(
              classes.input,
              formik.errors.lname && classes.inputError,
            )}
            id="password"
            name="password"
            type="password"
            label="password"
            placeholder=""
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.errors.password && (
            <div
              className={classes.error}
              data-type="error"
            >
              {formik.errors.password}
            </div>
          )}

          <p className={classes.forgotPwdLink}>
            <a href="/forgot-password">
              ¿Olvidó su contraseña?
            </a>
          </p>

          <Button
            className={classes.submitButton}
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            type="button"
            onClick={onSubmit(formik.values)}
          >
            {isSubmitting ? (
              <CircularProgress
                className={classes.spinner}
              />
            ) : (
              'INGRESAR'
            )}
          </Button>

          <p
            style={{
              marginTop: '40px',
              fontSize: '.8em',
              textAlign: 'center',
            }}
          >
            Copyright ©2021 Partido Liberación Nacional
            <br />
            TELEFONOS: 2549-5500 / 2549-5504 CENTRAL
            TELEFÓNICA
          </p>
        </form>
      </Container>
    </>
  );
};

export default withStyles(style)(Login);

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getFullBalanceById = _ced => {
  return axiosInstance.get(
    `gestores/cuadroSaldosEC/${_ced}`,
  );
};

export default {
  getBalanceById: getFullBalanceById,
};

/* eslint-disable max-len */
import RegistroGestores from '../Containers/RegistroGestores/RegistroGestores';
import Login from '../Containers/Login/Login';
import ForgotPassword from '../Containers/Login/ForgotPassword';
import MainPage from '../Containers/MainPage/MainPage';
import Dashboard from '../Containers/Dashboard/Dashboard';

// Registro Papeletas
import DelegadosCantonales from '../Containers/DelegadosCantonales/DelegadosCantonales';
import AddDelgadosCantonales from '../Containers/DelegadosCantonales/AddDelgadosCantonales';
import EditDelgadosCantonales from '../Containers/DelegadosCantonales/EditDelgadosCantonales';

import ComiteEjecutivoCantonal from '../Containers/ComiteEjecutivoCantonal/ComiteEjecutivoCantonal';
import AddComiteEjecutivoCantonal from '../Containers/ComiteEjecutivoCantonal/AddComiteEjecutivoCantonal';
import EditComiteEjecutivoCantonal from '../Containers/ComiteEjecutivoCantonal/EditComiteEjecutivoCantonal';

// Registro Depositos
import RegistroDepositos from '../Containers/RegistroDepositos/RegistroDepositos';
import AddDeposito from '../Containers/RegistroDepositos/AddDeposito';
import EditDeposito from '../Containers/RegistroDepositos/EditDeposito';

// Consulta Debitos
import ConsultaDebitos from '../Containers/ConsultaDebitos/ConsultaDebitos';

// Formularios
import FRMPapeletaDelegadosCantonales from '../Containers/Formulario/FRMPapeletaDelegadosCantonales';
import FRMComiteEjecutivoCantonal from '../Containers/Formulario/FRMComiteEjecutivoCantonal';
import FRMHojaInformacion from '../Containers/Formulario/FRMHojaInformacion';

// Consultas Públicas
import CPPapeletasDelegadosCantonales from '../Containers/ConsultasPublicas/CPPapeletasDelegadosCantonales';
import CPPapeletasComiteEjecutivoCantonal from '../Containers/ConsultasPublicas/CPPapeletasComiteEjecutivoCantonal';

// Adjudicación de Puestos
import APDelegadosCantonales from '../Containers/AdjudicacionPuestos/APDelegadosCantonales';
import APComiteEjecutivoCantonal from '../Containers/AdjudicacionPuestos/APComiteEjecutivoCantonal';

const routesList = [
  {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'login',
    path: '/login',
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    name: 'forgotPwd',
    path: '/forgot-password',
  },
  {
    auth: true,
    component: Dashboard,
    exact: true,
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    auth: true,
    component: DelegadosCantonales,
    exact: true,
    name: 'delegados-cantonales',
    path: '/delegados-cantonales',
  },
  {
    auth: true,
    component: AddDelgadosCantonales,
    exact: true,
    name: 'addDelgadosCantonales',
    path: '/delegados-cantonales/add',
  },
  {
    auth: true,
    component: EditDelgadosCantonales,
    exact: true,
    name: 'editDelgadosCantonales',
    path: '/delegados-cantonales/edit/:id',
  },
  {
    auth: true,
    component: ComiteEjecutivoCantonal,
    exact: true,
    name: 'comiteEjecutivoCantonal',
    path: '/comite-ejecutivo-cantonal',
  },
  {
    auth: true,
    component: AddComiteEjecutivoCantonal,
    exact: true,
    name: 'addComiteEjecutivoCantonal',
    path: '/comite-ejecutivo-cantonal/add',
  },
  {
    auth: true,
    component: EditComiteEjecutivoCantonal,
    exact: true,
    name: 'editComiteEjecutivoCantonal',
    path: '/comite-ejecutivo-cantonal/edit/:id',
  },
  {
    auth: false,
    component: RegistroGestores,
    exact: true,
    name: 'registro-gestores',
    path: '/registro-gestores',
  },
  {
    auth: true,
    component: RegistroDepositos,
    exact: true,
    name: 'registroDepositos',
    path: '/registro-depositos',
  },
  {
    auth: true,
    component: AddDeposito,
    exact: true,
    name: 'addDeposito',
    path: '/registro-depositos/add',
  },
  {
    auth: true,
    component: EditDeposito,
    exact: true,
    name: 'editDeposito',
    path: '/registro-depositos/edit/:id',
  },
  {
    auth: true,
    component: ConsultaDebitos,
    exact: true,
    name: 'consultaDebitos',
    path: '/consulta-debitos',
  },
  {
    auth: false,
    component: FRMPapeletaDelegadosCantonales,
    exact: true,
    name: 'frmPapeletaDelegadosCantonales',
    path: '/formulario/papeletas-delegados-cantonales',
  },
  {
    auth: false,
    component: FRMComiteEjecutivoCantonal,
    exact: true,
    name: 'frmComiteEjecutivoCantonal',
    path: '/formulario/comite-ejecutivo-cantonal',
  },
  {
    auth: false,
    component: FRMHojaInformacion,
    exact: true,
    name: 'FRMHojaInformacion',
    path: '/formulario/hoja-informacion',
  },
  {
    auth: false,
    component: CPPapeletasDelegadosCantonales,
    exact: true,
    name: 'cpPapeletasDelegadosCantonales',
    path:
      '/consulta-publica/papeletas-delegados-cantonales',
  },
  {
    auth: false,
    component: CPPapeletasComiteEjecutivoCantonal,
    exact: true,
    name: 'cpPapeletasComiteEjecutivoCantonal',
    path: '/consulta-publica/comite-ejecutivo-cantonal',
  },
  {
    auth: false,
    component: APDelegadosCantonales,
    exact: true,
    name: 'apDelegadosCantonales',
    path: '/adjudicacion-puestos/delegados-cantonales',
  },
  {
    auth: false,
    component: APComiteEjecutivoCantonal,
    exact: true,
    name: 'apComiteEjecutivoCantonal',
    path: '/adjudicacion-puestos/comite-ejecutivo-cantonal',
  },
];

export default routesList;
